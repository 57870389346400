import React from "react";

const Disclaimer = ({ darkTheme }) => {
  return (
    <div
      id="disclaimer"
      className="modal fade"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div
          className={
            "modal-content " + (darkTheme ? "bg-dark-2 text-light" : "")
          }
        >
          <div className="modal-header">
            <h5 className={"modal-title " + (darkTheme ? "text-white" : "")}>
              Copyright &amp; Disclaimer
            </h5>
            <button
              type="button"
              className={"btn-close " + (darkTheme ? "btn-close-white" : "")}
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body p-4">
          <div class="portfolio-disclaimer">
    <p>
        The content provided on this developer's portfolio website is for demonstration and illustrative purposes only. All projects, case studies, and examples showcased here are intended to highlight skills, capabilities, and experiences as a developer.
    </p>
    <p>
        While every effort has been made to ensure the accuracy and reliability of the information presented, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability of the projects, code snippets, or related content. Visitors are encouraged to verify any information before relying on it.
    </p>
    <p>
        Please note that the projects displayed may involve third-party libraries, frameworks, or APIs, and appropriate attribution is provided wherever applicable. However, we cannot be held responsible for any issues or damages arising from the use of third-party resources.
    </p>
    <p>
        Visitors are advised to exercise caution and conduct their own due diligence when using any code, techniques, or resources shared on this website. We shall not be liable for any direct, indirect, incidental, consequential, or special damages arising out of or in any way connected with the use of this portfolio or the information provided herein.
    </p>
    <p>
        By accessing and using this portfolio website, you agree to accept these terms and conditions. If you do not agree with any part of this disclaimer, you are kindly requested to refrain from accessing or using this website.
    </p>
</div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Disclaimer;
