import React from "react";

const TermsAndConditions = ({ darkTheme }) => {
  return (
    <div
      id="terms-policy"
      className="modal fade"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div
          className={
            "modal-content " + (darkTheme ? "bg-dark-2 text-light" : "")
          }
        >
          <div className="modal-header">
            <h5 className={"modal-title " + (darkTheme ? "text-white" : "")}>
              Terms &amp; Policy
            </h5>
            <button
              type="button"
              className={"btn-close " + (darkTheme ? "btn-close-white" : "")}
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body p-4">
         
    <h2>Terms and Conditions</h2>
    <ol>
        <li>
            <p>
                By accessing and using this website, you agree to be bound by these terms and conditions. If you do not agree with any part of these terms and conditions, you must not use this website.
            </p>
        </li>
        <li>
            <p>
                The content of this website is for general informational purposes only. It is subject to change without notice.
            </p>
        </li>
        <li>
            <p>
                We do not provide any warranty or guarantee as to the accuracy, completeness, suitability, or timeliness of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors, and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.
            </p>
        </li>
        <li>
            <p>
                Your use of any information or materials on this website is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services, or information available through this website meet your specific requirements.
            </p>
        </li>
        <li>
            <p>
                This website contains material which is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance, and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.
            </p>
        </li>
    </ol>
    <p>
        These terms and conditions are subject to change without notice. Your continued use of this website following any changes signifies your acceptance of the updated terms and conditions.
    </p>
</div>

          </div>
        </div>
      </div>
   
  );
};

export default TermsAndConditions;
